import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ENVIRONMENT } from '../tokens/app.tokens';

@Injectable({
    providedIn: 'root',
})
export class SseService {
    private sseUrl = '';
    private eventSource: EventSource | null = null;
    private reconnectDelay = 1000; // Initial delay in ms
    private maxReconnectDelay = 30000; // Max delay in ms
    private isReconnecting = false;
    // Timer for tracking heartbeat timeout
    private heartbeatTimeout: any;

    constructor(
        private authService: AuthService,
        @Inject(ENVIRONMENT) private environment: any
    ) {
        this.sseUrl = `${environment.sse_server}/events`;
    }

    async getServerSentEvents(): Promise<Observable<any>> {
        const userProfile = this.authService.getUserProfileStatic();
        if (!userProfile) {
            throw new Error('User is not authenticated or profile is missing.');
        }

        return new Observable((observer) => {
            // Define the connection function.
            const connect = async () => {
                // Always fetch a fresh token on (re)connect.
                const token = await this.getFreshToken();
                const urlWithToken = `${this.sseUrl}?auth=${encodeURIComponent(token)}`;
                console.log('Connecting to SSE with URL:', urlWithToken);

                // Clear any existing connection.
                if (this.eventSource) {
                    console.log('Clearing existing SSE connection.');
                    this.eventSource.close();
                    this.eventSource = null;
                }

                // Create a new EventSource connection.
                this.eventSource = new EventSource(urlWithToken);

                // We'll consider the heartbeat missed if no heartbeat event is received within HEARTBEAT_TIMEOUT_MS.
                // (Assuming the server sends a heartbeat every 60 seconds, we use 70 seconds here.)
                const HEARTBEAT_TIMEOUT_MS = 70 * 1000;

                // Helper function to (re)start the heartbeat timer.
                const resetHeartbeatTimeout = () => {
                    if (this.heartbeatTimeout) {
                        clearTimeout(this.heartbeatTimeout);
                    }
                    this.heartbeatTimeout = setTimeout(() => {
                        console.warn('Missed heartbeat detected. Reconnecting immediately.');
                        // Close the current connection.
                        if (this.eventSource) {
                            this.eventSource.close();
                            this.eventSource = null;
                        }
                        // Notify the observer and trigger reconnection.
                        observer.error(new Error('Missed heartbeat'));
                        this.scheduleReconnect(connect);
                    }, HEARTBEAT_TIMEOUT_MS);
                };

                // When the connection is opened, reset the heartbeat timer.
                this.eventSource.onopen = () => {
                    console.log('SSE connection established.');
                    resetHeartbeatTimeout();
                };

                // Listen for regular messages.
                this.eventSource.onmessage = (event) => {
                    try {
                        // We assume non-heartbeat messages are JSON data.
                        const data = JSON.parse(event.data);
                        console.log('SSE Data received:', data);
                        observer.next(data);
                        // Reset the heartbeat timer whenever any valid message arrives.
                        resetHeartbeatTimeout();
                        this.resetReconnectDelay();
                    } catch (err) {
                        console.error('Error parsing SSE data:', err, event.data);
                        observer.error(err);
                    }
                };

                // Listen for heartbeat events.
                // Note: This will only work if the server sends heartbeats with a named event:
                // e.g., "event: heartbeat\ndata: { ... }\n\n"
                this.eventSource.addEventListener('heartbeat', (event: MessageEvent) => {
                    try {
                        const heartbeatData = JSON.parse(event.data);
                        console.log('Heartbeat received at:', heartbeatData.timestamp || new Date().toISOString());
                    } catch (error) {
                        console.log('Heartbeat received at:', new Date().toISOString());
                    }
                    // Reset the heartbeat timer on every heartbeat.
                    resetHeartbeatTimeout();
                });

                // Handle errors.
                this.eventSource.onerror = async (error: any) => {
                    console.error('SSE connection error:', error);

                    // If the error indicates an authentication issue (401), try to refresh the token.
                    if (error?.status === 401) {
                        console.warn('SSE received 401 error, refreshing token...');
                        try {
                            // Force a token refresh.
                            await this.getFreshToken();
                        } catch (tokenError) {
                            console.error('Error refreshing token:', tokenError);
                            observer.error(tokenError);
                            return;
                        }
                    }

                    observer.error(error);

                    // Close the current connection.
                    if (this.eventSource) {
                        this.eventSource.close();
                        this.eventSource = null;
                    }

                    // Clear the heartbeat timer.
                    if (this.heartbeatTimeout) {
                        clearTimeout(this.heartbeatTimeout);
                    }

                    // Schedule reconnection.
                    this.scheduleReconnect(connect);
                };
            };

            // Start the connection.
            connect();

            // Cleanup on unsubscribe.
            return () => {
                console.log('Closing SSE connection.');
                if (this.eventSource) {
                    this.eventSource.close();
                    this.eventSource = null;
                }
                if (this.heartbeatTimeout) {
                    clearTimeout(this.heartbeatTimeout);
                }
            };
        });
    }

    private async getFreshToken(): Promise<string> {
        const user = await this.authService.getUser();
        if (user) {
            // Ensure that your AuthService actually refreshes the token if it’s expired.
            const token = await user.stsTokenManager.accessToken;
            console.log('Token refreshed successfully.');
            return token;
        } else {
            throw new Error('User is not authenticated or profile is missing.');
        }
    }

    private scheduleReconnect(connect: () => void) {
        if (this.isReconnecting) {
            return;
        }
        this.isReconnecting = true;
        const delay = this.reconnectDelay;
        console.log(`Reconnecting in ${delay / 1000} seconds...`);
        setTimeout(() => {
            this.isReconnecting = false;
            this.reconnectDelay = Math.min(this.reconnectDelay * 2, this.maxReconnectDelay);
            connect();
        }, delay);
    }

    private resetReconnectDelay() {
        this.reconnectDelay = 1000; // Reset to the initial delay.
    }
}
